<template>
	<div>
		<template v-if="isParticipationDetailsPage">
			<NuxtPage/>
		</template>
		<template v-else>
			<app-app-header v-model="indexStore.isHeaderVisible"
							:title="event.display_name"
							:breadcrumbs="[event.type.value === EventType.Event ? {text: $t('label.competition_calendar'), to: $web('events.index')} : {text: $t('label.sports_map'), to: $web('events.sports_map')}]"
							:color="eventStore.colorStyle"
							:to="$web('events.show', [event._key])" flat-on-top>
				<div class="flex items-center justify-end">
					<event-bookmark-button v-if="!eventStore.isParticipant && !isSubResourcePage" :event="eventStore.event"
										   v-model="eventStore.isBookmarkModalVisible"
										   v-model:active="eventStore.authPermissions.is_following"
										   v-model:type="eventStore.selectedBookmarkType"
										   @added-participation="handleAddedCustomParticipation"
										   fab/>

					<dropdown v-model="isNavMenuActive" :menu-title="eventStore.event.display_name" :actions="navItems" class="ml-12 sm:ml-16">
						<btn :aria-label="$t('label.menu')" fab>
							<menu-btn :model-value="isNavMenuActive" small :color="$config.public.colors.white"/>
						</btn>
					</dropdown>

				</div>
			</app-app-header>

			<app-content>
				<page-content xs-full sm-full :background="event.background_color" :has-app-bottom-bar="hasAppBottomBar" :without-mobile-bottom-bar="!isMobileNavVisible">
					<lazy-cdn-background-image v-if="event.background_image && $device.isDesktop" class="fixed w-screen h-screen top-0 left-0 right-0 bottom-0"
											   :src="event.background_image"
											   :ratio="1920/1080" :width="1920" cover immediate/>

					<container xs-full sm-full class="stretch-layout">
						<card v-if="!isSubResourcePage" xs-tile sm-tile :style="!event.is_test ? `border-top: 2px solid ${eventStore.colorStyle}` : null">
							<lazy-test-mode-line v-if="event.is_test"/>

							<div class="flex flex-column sm:flex-row">
								<div class="sm:pt-16 sm:pb-16 sm:pl-16 md:pt-24 lg:pl-24 overflow-hidden" :class="isSubResourcePage ? 'md:pb-24' : null">
									<nuxt-link class="w-full sm:w-264 block relative" :to="$web('events.show', event._key)">
										<aspect-ratio-box :ratio="3/2">
											<parallax only-mobile>
												<cdn-image :src="eventStore.event.avatar_thumbnail"
														   :alt="event.display_name"
														   cover
														   :ratio="3/2"
														   :size="{width: '100vw', sm: {width: 264}}"
														   class="sm:rounded" immediate/>
											</parallax>
										</aspect-ratio-box>
									</nuxt-link>
								</div>

								<card :shadow="false" no-spacing tile class="mt--16 sm:mt-4 rounded-t-xl">
									<card-text class="pt-24 md:pt-30 sm:pb-20 sm:pl-24 lg:pl-32">

										<nuxt-link class="block font-heading text-heading-color font-bold leading-normal text-2xl md:text-3xl mb-4"
												   :to="$web('events.show', event._key)">{{ event.display_name }}
										</nuxt-link>

										<sport-type-text :sport-types="event.sportTypes" truncate>
											<meta-text class="font-bold text-text-color">{{ event.type.title }}</meta-text>
										</sport-type-text>

										<info-list class="mt-16">
											<info-list-item v-if="[EventStatus.Draft,EventStatus.Unverified, EventStatus.Test].indexOf(event.status.value) >= 0"
															:color="event.status.color"
															:text="event.status.title"
															:icon="icons.warning" :help="event.status.description"/>
											<info-list-item v-if="event.is_private"
															color="warning"
															:text="$t('label.private_event')"
															:icon="icons.warning"/>

											<info-list-item :icon="icons.date" :text="$date(event.start_date).formatDateTimeRange(event.end_date)"/>
											<info-list-item :icon="icons.location">
												<nuxt-link :to="$web('events.map.index', event._key)">{{ event.full_address }}</nuxt-link>
											</info-list-item>

											<info-list-item v-if="event.attendance_mode !== EventAttendanceMode.Attendance" :icon="icons.virtual" :help="$t('help.event.virtual')">
												<a :href="`${$config.public.aboutUrl}/spoferan/virtual-events`"
												   target="_blank">{{
														event.attendance_mode === EventAttendanceMode.Virtual ? $t('label.virtual_event') : $t('label.partial_virtual_event')
													}}</a>
											</info-list-item>

											<info-list-item v-if="event.register_start_date && event.register_end_date && $date().isBefore(event.start_date)"
															:icon="icons.register">
							<span v-if="$date().isBefore(event.register_start_date)">
								{{ $t('text.participation_open_at', {start: $date(event.register_start_date).formatDateTimeRange(false)}) }}
								</span>
												<span v-else-if="$date().isBefore(event.register_end_date)">{{
														$t('text.participation_open_until', {end: $date(event.register_end_date).formatDateTimeRange(true)})
													}}</span>
												<span v-else class="text-warning">{{ $t('label.registration_closed') }}</span>
											</info-list-item>

											<info-list-item v-if="event.currentMinPrice && $date().isBefore(event.register_end_date)" :icon="icons.fee" class="sm:none">
												<nuxt-link v-if="event.currentMaxPrice && event.currentMaxPrice.cents <= 0" class="text-success"
														   :to="$web('events.participation_classes.index', event._key)">{{ $t('label.free') }}
												</nuxt-link>
												<nuxt-link v-else-if="event.currentMinPrice.cents !== event.currentMaxPrice.cents" class="text-success"
														   :to="$web('events.participation_classes.index', event._key)"
														   v-html="$t('label.participation_start_end_price', {start: toEuro(event.currentMinPrice.cents), end: toEuro(event.currentMaxPrice.cents)})"/>
												<nuxt-link v-else class="text-success" :to="$web('events.participation_classes.index', event._key)"
														   v-html="$t('label.participation_start_price', {price: toEuro(event.currentMinPrice.cents)})"/>
											</info-list-item>

											<template v-if="event.rating.count">
												<info-list-item v-if="event.rating.key === event._key" :icon="icons.create_rating" class="md:none">
													<div class="flex items-center">
														<div>
															<form-rating readonly left v-model.number="event.rating.avg" x-small/>
														</div>
														<template v-if="event.rating.count">
															<nuxt-link v-if="eventStore.hasActivePage(EventPageType.Ratings)" :to="$web('events.ratings.index', event._key)"
																	   class="text-3xs sm:text-2xs ml-8 leading-tight text-subtext-color">
																{{ $t('param_label.ratings', event.rating.count, {'count': event.rating.count}) }}
															</nuxt-link>
															<span v-else class="text-3xs sm:text-2xs ml-4 leading-tight text-subtext-color">
												{{ $t('param_label.ratings', event.rating.count, {'count': event.rating.count}) }}
											</span>
														</template>
													</div>
												</info-list-item>
												<info-list-item v-else :icon="icons.create_rating" class="md:none">
													<div class="flex flex-column">
														<form-rating readonly left v-model.number="event.rating.avg" x-small/>
														<nuxt-link :to="$web('events.ratings.index', event.rating.key)"
																   class="text-3xs sm:text-2xs ml-4 leading-tight text-subtext-color">
															{{ $t('action.to_ratings_of_last_event') }}
														</nuxt-link>
													</div>
												</info-list-item>
											</template>

										</info-list>
									</card-text>
								</card>
							</div>

							<header-toolbar v-if="!isSubResourcePage" id="tabs" sticky-mobile>
								<tabs class="h-full">
									<tab v-for="(page, pageKey) in tabEventPages" :color="eventStore.colorStyle" replace exact
										 :key="pageKey" :to="page.path" :text="page.title"/>
								</tabs>
							</header-toolbar>
						</card>

						<!-- To allow the devices to scroll to the tabs even though a page has no content, we need to set the min height for our mobile profile page tab focus scrolling logic. -->
						<!-- 56px = header; 42px = tabs bar; 48px = mobile bottom bar; 56px = app bottom bar ; 8px = spacing bottom -->
						<div id="content" class="stretch-layout"
							 :style="{minHeight: $device.isDesktop ? null : `calc(100vh - 56px - 42px - ${isMobileNavVisible ? '48px' : '8px'} - ${isEventInfoBarVisible ? '56px' : '0px'} - 8px)`}">
							<NuxtPage keep-alive/>
						</div>

					</container>

					<app-bottom-bar v-if="isEventInfoBarVisible" id="app-bottom-bar">
						<div class="flex justify-between items-center overflow-hidden">
							<div class="flex items-center overflow-auto">
								<lazy-achievements-list ssr no-missing :model-key="eventStore.event.id" model-name="event" :skeleton-count="1"/>

								<div v-if="$device.isDesktop && event.rating.count"
									 class="flex">
									<divider :margin="16" vertical/>

									<div v-if="event.rating.key === event._key" class="flex flex-column">
										<form-rating readonly left v-model.number="event.rating.avg"/>
										<template v-if="event.rating.count">
											<nuxt-link v-if="eventStore.hasActivePage(EventPageType.Ratings)" :to="$web('events.ratings.index', event._key)"
													   class="text-2xs sm:text-xs leading-tight mt-2 ml-4 text-subtext-color hover:text-primary">
												{{ $t('param_label.ratings', event.rating.count, {'count': event.rating.count}) }}
											</nuxt-link>
											<span v-else class="text-2xs sm:text-xs leading-tight mt-2 ml-4 text-subtext-color">
												{{ $t('param_label.ratings', event.rating.count, {'count': event.rating.count}) }}
											</span>
										</template>
									</div>
									<div v-else class="flex flex-column">
										<form-rating readonly left v-model.number="event.rating.avg"/>
										<nuxt-link :to="$web('events.ratings.index', event.rating.key)"
												   class="text-4xs sm:text-2xs md:text-xs leading-tight mt-2 ml-4 text-subtext-color hover:text-primary">
											{{ $t('action.to_ratings_of_last_event') }}
										</nuxt-link>
									</div>
								</div>
							</div>

							<div class="flex items-center flex-grow-x-1 ml-8">
								<client-only>
									<wrapper class="min-w-120" grow>
										<div v-if="event.currentMinPrice" class="none sm:block min-w-140 mr-16 text-right">
											<nuxt-link v-if="event.currentMaxPrice && event.currentMaxPrice.cents <= 0"
													   class="mb-0 leading-none text-sm text-success"
													   :to="$web('events.participation_classes.index', event._key)">{{ $t('label.free') }}
											</nuxt-link>
											<nuxt-link v-else-if="event.currentMinPrice.cents !== event.currentMaxPrice.cents" class="mb-0 leading-none text-sm text-success"
													   :to="$web('events.participation_classes.index', event._key)"
													   v-html="$t('label.participation_start_end_price', {start: toEuro(event.currentMinPrice.cents), end: toEuro(event.currentMaxPrice.cents)})"/>
											<nuxt-link v-else class="mb-0 leading-none text-sm text-success" :to="$web('events.participation_classes.index', event._key)"
													   v-html="$t('label.participation_start_price', {price: toEuro(event.currentMinPrice.cents)})"/>

											<p class="mb-0 mt-2 block leading-none text-gray-400 text-4xs">{{ $t('text.including_fees') }}</p>
										</div>
										<btn v-if="eventStore.authPermissions.participation_ids.length > 1" color="success" large :to="$web('my.events.show', [event._key])">
											{{ $t('label.my_participations') }}
										</btn>
										<btn v-else-if="eventStore.authPermissions.participation_ids.length === 1" color="success" large
											 :to="$web('events.participations.show', [event._key, eventStore.authPermissions.participation_ids[0]])">
											{{ $t('label.my_participation') }}
										</btn>

										<form-btn v-if="$auth.user && $auth.user.is_admin && event.status.value === EventStatus.Unverified"
												  @success="() => window.location.reload()"
												  color="success" :method="'PUT'" :action="$api('events.update', event._key)"
												  :confirm="{title: $t('confirm.event.verify.title'), message: $t('confirm.event.verify.message', {name: event.display_name}), accept: $t('confirm.event.verify.accept'), cancel: $t('confirm.event.verify.cancel'), type: 'info'}"
												  :data="{status: EventStatus.Published}">
											<icon :icon="icons.check"/>
											<span>{{ $t('action.event.verify') }}!</span>
										</form-btn>

										<btn v-if="event.status.value === EventStatus.Canceled" :to="$web('events.show', event._key)" color="error"
											 class="sm:min-w-200" large>
											<span>{{ event.status.title }}</span>
										</btn>
										<template v-else-if="eventStore.isFinished">
											<btn v-if="eventStore.isParticipant && !eventStore.authPermissions.has_rated" :to="$web('events.ratings.create', event._key)" :color="eventStore.colorStyle"
												 outlined
												 large>
												<icon :icon="icons.create_rating"/>
												<span class="none lg:block">{{ $t('action.event_rating.create') }}</span>
											</btn>
											<btn v-else-if="eventStore.hasActivePage(EventPageType.Results)" color="success" :to="$web('events.results.index', event._key)" large>
												<icon :icon="icons.rank"/>
												<span :class="{'none lg:block': eventStore.isParticipant}">{{ $t('label.results') }}</span>
											</btn>
											<btn v-else-if="!event.has_managed_sign_up && event.external_url" color="success" large :to="event.external_url" target="_blank" nofollow>
												<icon v-if="event.external_url" :icon="icons.external_link"/>
												<icon v-else :icon="icons.rank"/>
												<span :class="{'none lg:block': eventStore.isParticipant}">{{ $t('label.results') }}</span>
											</btn>
										</template>

										<template v-else>
											<btn v-if="event.has_managed_sign_up && event.register_start_date && $date().isBefore(event.register_start_date)" color="warning"
												 large
												 class="text-2xs">
												<span>{{ $t('text.participation_open_at', {start: $date(event.register_start_date).format('DD. MMM.')}) }}</span>
											</btn>
											<template v-else-if="event.has_managed_sign_up && event.register_end_date && $date().isAfter(event.register_end_date)">
												<btn v-if="eventStore.hasActivePage(EventPageType.Results)" :color="eventStore.colorStyle" large
													 :to="$web('events.results.index', event._key)">
													<icon :icon="icons.rank"/>
													<span>{{ $t('label.results') }}</span>
												</btn>
												<btn v-else-if="eventStore.hasActivePage(EventPageType.Participations)" :color="eventStore.colorStyle" large
													 :to="$web('events.participations.index', event._key)">
													<span>{{ $t('label.participants') }}</span>
												</btn>
												<btn v-else color="warning" large>
													<span>{{ $t('label.registration_closed') }}</span>
												</btn>
											</template>
											<template v-else-if="(event.has_managed_sign_up || event.external_url || event.registration_info) && (event.register_start_date || event.register_end_date)">
												<btn v-if="eventStore.authPermissions.participation_ids && eventStore.authPermissions.participation_ids.length"
													 :to="event.has_managed_sign_up ? $web('events.participations.create', event._key) : (event.registration_info ? undefined : event.external_url)"
													 @click="!event.has_managed_sign_up && event.registration_info ? (showSignUpInfoModal = true) : undefined"
													 :target="event.has_managed_sign_up ? undefined : '_blank'"
													 nofollow
													 large
													 outlined
													 class="max-w-48 sm:max-w-200"
													 color="success">
													<icon v-if="!event.registration_info && event.external_url" :icon="icons.external_link"/>
													<icon v-else :icon="icons.register"/>
													<span class="none lg:block">{{ $t('action.participate_another') }}</span>
												</btn>
												<btn v-else
													 :to="event.has_managed_sign_up ? $web('events.participations.create', event._key) : (event.registration_info ? undefined : event.external_url)"
													 @click="!event.has_managed_sign_up && event.registration_info ? (showSignUpInfoModal = true) : undefined"
													 :target="event.has_managed_sign_up ? undefined : '_blank'"
													 nofollow
													 large
													 class="sm:min-w-200"
													 color="success">
													<icon v-if="!event.registration_info && event.external_url" :icon="icons.external_link"/>
													<span>{{ $t('action.participate_now') }}!</span>
												</btn>
											</template>
										</template>

										<template v-if="$device.isDesktop">
											<event-bookmark-button v-if="!eventStore.isParticipant && !isSubResourcePage" :event="eventStore.event"
																   v-model="eventStore.isBookmarkModalVisible"
																   v-model:active="eventStore.authPermissions.is_following"
																   v-model:type="eventStore.selectedBookmarkType"
																   large
																   @added-participation="handleAddedCustomParticipation"
																   class="max-w-48 sm:max-w-56"/>

											<dropdown top class="max-w-48 sm:max-w-56" :actions="actions">
												<btn :aria-label="$t('label.actions')" icon large class="w-full">
													<icon :icon="icons.options"/>
												</btn>
											</dropdown>

										</template>

									</wrapper>
								</client-only>
							</div>
						</div>
					</app-bottom-bar>

					<lazy-modal v-if="event.registration_info" :title="$t('label.registration_info')" v-model="showSignUpInfoModal">
						<div v-html="event.registration_info"/>

						<template v-if="event.external_url" #button>
							<btn :to="event.external_url"
								 nofollow
								 target="_blank"
								 color="success">
								<icon :icon="icons.external_link"/>
								<span>{{ $t('action.participate_now') }}!</span>
							</btn>
						</template>
					</lazy-modal>
				</page-content>
			</app-content>

			<template v-if="eventStore.event.allows_partner_ads">
				<template v-if="$device.isMobile">
					<qmn-ad :config="QmnAds.MobilePageTracker" class="none"/>
				</template>
				<template v-else>
					<qmn-ad :config="QmnAds.DesktopSitebarAd"/>
					<qmn-ad :config="QmnAds.DesktopPageTracker" class="none"/>
				</template>
			</template>
		</template>
	</div>
</template>

<script setup lang="ts">
import {mdiCashMultiple, mdiCellphoneNfc, mdiLogin, mdiSquareEditOutline, mdiTrophy} from '@mdi/js';
import {EventAttendanceMode, EventPageType, EventStatus, EventType, toEuro} from "@spoferan/spoferan-ts-core";
import {checkIcon, dateIcon, externalLinkIcon, locationIcon, optionsIcon, warningIcon} from "@spoferan/nuxt-spoferan/icons";
import type {DropdownAction} from "@spoferan/nuxt-spoferan/types";
import type {Ref} from "vue";
import {useEventStore} from "../../store/event";
import {useIndexStore} from "../../store";
import {QmnAds} from "../../config/qmn";

const route = useRoute();
const {$image, $date, $auth, $device, $web, $analytics} = useNuxtApp();
const eventStore = useEventStore();
const indexStore = useIndexStore();
const config = useRuntimeConfig();
const {t} = useI18n();
const {value: cacheBusterValue} = useCacheBuster('event_ts');

// Refresh permissions on user change as required when user gets authenticated on client side after SSR hydration
watch(() => $auth.token, () => {
	eventStore.refreshAuthPermissions();
}, {
	// Run immediately when token is already set (CSR) or the token will not be set as the user is not logged in
	immediate: !!$auth.token || !$auth.loggedIn
});

const {data: event} = await useApiFetch(`/v1/view/events/${route.params.event}`, {
	guest: true,
	params: {
		ts: cacheBusterValue
	}
});

// If a hashed '#' char specified as '%23' is given in the URL, we do not want it for comparison as this would cause infinite redirects
const plainEventParam = route.params.event.split('#')[0];
if (event && event._key !== plainEventParam) {
	await navigateTo({params: {event: event._key}}, {
		redirectCode: 308
	});
}

eventStore.event = event;

const tabEventPages = event.pages.filter(page => page.is_visible_on_nav);

const showSignUpInfoModal = ref(false);

const manageUrl = computed(() => {
	// We pass the auth token for links to our external services to keep the user authenticated
	let authParam = '';
	if ($device.isNative && $auth.loggedIn) {
		authParam = `?auth=${$auth.token}`
	}

	return `${config.public.resultasticUrl}/${event._key}${authParam}`;
});

const actions: Ref<DropdownAction[]> = computed(() => {
	return [
		{
			name: t('action.share'),
			callback: () => {
				indexStore.share({
					id: `event-${event._key}`
				})
			}
		},
		eventStore.authPermissions.is_member ? {
			name: t('label.my_member_page'),
			to: $web('events.show.membership', event._key)
		} : {
			name: t('action.become_a_volunteer'),
			to: $web('events.members.join', event._key)
		},
		eventStore.authPermissions.access_resultastic ? {
			name: t('action.manage'),
			to: manageUrl.value
		} : undefined,
	].filter(Boolean);
});

const isNavMenuActive = ref(false);
const navItems = computed(() => {
	const items: object[] = tabEventPages.map(page => {
		return {
			name: page.title,
			to: page.path,
			replace: true
		}
	});

	items.push({divider: true});
	items.push(...actions.value);

	return items;
});

const isParticipationDetailsPage = computed(() => {
	return isOnEventPagePath(['participations/']);
});

const isEventInfoBarVisible = computed(() => {
	// We do not show the event info bar on pages with the following path prefixes
	return !isOnEventPagePath(['participate', 'shop', 'jobs', 'ratings/create', 'tracking', 'results', 'stay', 'map', 'join', 'assignment', 'photos']);
});

const isMobileNavVisible = computed(() => {
	return !route.name.startsWith('events-event-participate');
});

const hasAppBottomBar = computed(() => {
	return isEventInfoBarVisible.value || (isOnEventPagePath(['photos', 'jobs/']) && !isOnEventPagePath(['photos/cart']));
});

const isSubResourcePage = computed(() => {
	return isOnEventPagePath(['participate', 'assignment']);
});

const icons = {
	register: mdiLogin,
	fee: mdiCashMultiple,
	rank: mdiTrophy,
	virtual: mdiCellphoneNfc,
	create_rating: mdiSquareEditOutline,
	external_link: externalLinkIcon,
	check: checkIcon,
	warning: warningIcon,
	options: optionsIcon,
	date: dateIcon,
	location: locationIcon,
};

function isOnEventPagePath(paths: string[]) {
	return paths.some(prefixPath => {
		// A test event might has uppercase letters
		return route.path.toLowerCase().startsWith($web('events.show', route.params.event.toLowerCase()) + '/' + prefixPath);
	})
}

function handleAddedCustomParticipation() {
	eventStore.refreshAuthPermissions();
}

onMounted(() => {
	if (eventStore.event.allows_partner_ads) {
		$analytics.trackQmnPageImpression();
	}
});

useMeta({
	title: eventStore.buildPageTitle(eventStore.event.display_name),
	image: $image.format(eventStore.event.avatar_thumbnail),
	noIndex: !eventStore.event.is_public,
});

</script>