<template>
	<div>
		<tooltip :text="isActive ? t('action.remove_from_profile') : t('action.add_to_profile')" top>
			<form-btn v-if="isActive" :action="`/v1/events/${props.event.id}/followers`"
					  :large="props.large"
					  :fab="props.fab"
					  class="w-full" icon
					  :aria-label="t('action.remove_from_profile')"
					  notification="removed"
					  :lang-params="{name: props.event.display_name}"
					  @success="handleRemovedBookmark"
					  method="DELETE"
					  :color="props.event.color">
				<icon :icon="icons.undo_bookmark"/>
			</form-btn>
			<btn v-else :large="props.large"
				 :fab="props.fab"
				 class="w-full" icon
				 :aria-label="t('action.add_to_profile')"
				 @click="isActionModalVisible = true">
				<icon :icon="icons.bookmark"/>
			</btn>
		</tooltip>

		<ajax-form-modal v-model="isActionModalVisible"
						 ref="formModalComponent"
						 :form-options="{action: selectedBookmarkType?.action, method: 'POST', data: addParticipationData, notification: 'added'}"
						 :modal-options="{title: t('action.add_to_profile'), subtitle: selectedBookmarkType ? selectedBookmarkType.title : undefined, showCloseButton: !selectedBookmarkType, buttonBreakColumn: 'xs'}"
						 :submit-text="t('action.add_participation')"
						 :hide-submit="!selectedBookmarkType || !$auth.loggedIn || selectedBookmarkType.hide_submit"
						 @cancel="handleCanceledBookmark"
						 @success="handleAddedBookmark">

			<feature-card v-if="!$auth.loggedIn"
						  :title="t('view.my.events.index.title')"
						  :description="t('view.my.events.index.description')"
						  image="/features/athletes/event-planner.svg" flat auth-required @login="handleCanceledBookmark"/>
			<div v-else-if="!selectedBookmarkType" class="stretch-layout h-full">
				<add-btn v-for="type in bookmarkTypes" :key="type.value" flat outlined class="flex-grow-1 my-8" :icon="type.icon" :text="type.title" :help="type.description"
						 :disabled="type.disabled" @click="selectBookmarkType(type)"/>
			</div>
			<template v-else-if="selectedBookmarkType.value === 'participation'">
				<manual-participation-form-inputs :data="addParticipationData" user-key="my" fixed-event/>
			</template>
			<template v-else>
				<loader/>
			</template>

			<template v-if="selectedBookmarkType && $auth.loggedIn" #footer>
				<btn @click="selectedBookmarkType = null">
					<icon :icon="icons.back"/>
					<span>{{ t('action.back') }}</span>
				</btn>
			</template>
		</ajax-form-modal>
	</div>
</template>

<script setup lang="ts">
import {mdiCalendarHeart, mdiHeart, mdiHeartOutline, mdiLogin} from '@mdi/js';
import type {EventPreview} from "@spoferan/spoferan-ts-core";
import {backIcon} from "@spoferan/nuxt-spoferan/icons";
import type {PropType} from "vue";

const props = defineProps<{
	event: EventPreview,
	fab?: boolean,
	large?: boolean,
}>();
const emit = defineEmits(['accepted', 'declined', 'added-participation', 'added-bookmark']);

const isActionModalVisible = defineModel({
	type: Boolean,
	default: false
});
const type = defineModel('type', {
	type: String as PropType<'participation' | 'bookmark' | null>,
	default: null
});
watch(type, (newValue) => {
	setBookmarkTypeByValue(newValue);
});
const isActive = defineModel('active', {
	type: Boolean,
	default: false
});
watch(isActive, (newValue) => {
	if (newValue) {
		setBookmarkTypeByValue(type.value);
	}
});

const {t} = useI18n();
const {$auth, $analytics} = useNuxtApp();
const route = useRoute();

const icons = {
	bookmark: mdiHeartOutline,
	undo_bookmark: mdiHeart,
	back: backIcon
};

const bookmarkTypes = [
	{
		value: 'participation',
		title: t('action.add_participation'),
		description: props.event.has_managed_sign_up ? t('text.event.add_manual_participation_for_managed_event', {event: props.event.display_name}) : t('text.participation.add_event_by_input'),
		icon: mdiLogin,
		action: `/v1/athletes/my/events/${props.event.id}/manual-participations`,
		hide_submit: false,
		disabled: props.event.has_managed_sign_up
	},
	{
		value: 'bookmark',
		title: t('label.interested'),
		description: t('text.event.bookmark'),
		icon: mdiCalendarHeart,
		action: `/v1/events/${props.event.id}/followers`,
		hide_submit: true
	},
].sort((a) => a.disabled ? -1 : 1);

const selectedBookmarkType = ref(null);
const addParticipationData = ref({});
const formModalComponent = ref(null);

function selectBookmarkType(bookmarkType) {
	type.value = bookmarkType.value;
}

function setBookmarkTypeByValue(typeValue: string | null) {
	if (!typeValue) {
		selectedBookmarkType.value = null;
		return;
	}

	const bookmarkType = bookmarkTypes.find((itType) => itType.value === typeValue);
	if (!bookmarkType || bookmarkType?.disabled) {
		return;
	}

	if (bookmarkType.value === 'bookmark') {
		addParticipationData.value = {};
		selectedBookmarkType.value = bookmarkType;
		nextTick(() => {
			formModalComponent.value.validationSubmit();
		})
	} else {
		addParticipationData.value = {
			club_id: $auth.user?.club_id,
			event_id: props.event.id,
		};
		selectedBookmarkType.value = bookmarkType;
	}
}

function handleCanceledBookmark() {
	type.value = null;
	isActionModalVisible.value = false;
}

function handleAddedBookmark() {
	if (type.value === 'bookmark') {
		$analytics.trackEvent('follow', {model_type_name: props.event.model_name,
			model_key: props.event._key
		});
	}

	isActive.value = true;
	isActionModalVisible.value = false;
	type.value = null;

	if (type.value === 'participation') {
		emit('added-participation');
	} else if (type.value === 'bookmark') {
		emit('added-bookmark');
	}
}

function handleRemovedBookmark() {
	isActive.value = false;

	$analytics.trackEvent('unfollow', {model_type_name: props.event.model_name,
		model_key: props.event._key
	});
}
</script>